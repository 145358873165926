<template>
  <div class="module_one_block">
    <div class="module_one_header_block">
      <div class="module_one_header_container">
        <div class="module_one_header_label">
          Финансовая грамотность и как ее повысить?
        </div>
      </div>
    </div>
    <div class="module_one_main_block">
      <div class="module_one_main_container">
        <div class="module_one_content_container">
          <div class="module_one_plot">
            <a href="#" class="module_one_plot_module">
              О курсе
            </a>
            <a href="#" class="module_one_plot_about">
              Модуль 1
            </a>
            <a href="#" class="module_one_plot_module">
              Модуль 2
            </a>
            <a href="#" class="module_one_plot_module">
              Модуль 3
            </a>
            <a href="#" class="module_one_plot_module">
              Итоговое тестирование
            </a>
          </div>
        </div>
        <div class="module_one_desc">
          <div class="module_two_bad_desc_testing">
            <div class="module_two_desc_test_time">Результат:</div>
            <div class="module_two_desc_test_label">
              Тестирование
            </div>
            <div class="module_two_desc_test_btn">
              <!--              <div class="module_two_desc_test_btn_start">Пройдено</div>-->
              <div class="courses_list_bad_item_date">
                <div class="module_two_desc_test_btn_start">Не пройдено</div>
                <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                <div class="my_courses_list_item_between">|</div>
                <div class="my_courses_list_item_end">Проходной балл: 60%</div>
                <div class="my_courses_list_item_again">Пройти еще раз</div>
              </div>
              <div class="module_two_desc_testing_result">53%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              1.  Culture & Art
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 98%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              2.  Learning Languages
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 90%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>
          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  School & Education
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Пройдено</div>
              <div class="module_one_desc_item_mark">Результат: 89%</div>
            </div>
          </div>

          <div class="module_one_desc_item">
            <div class="module_one_desc_item_label">
              3.  Отзыв
            </div>
            <div class="module_one_desc_item_result">
              <div class="module_one_desc_item_text">Благодарим за отзыв!</div>
            </div>
          </div>
          <div class="module_one_desc_btn">
            <a href="#" class="module_one_desc_btn_back">Назад</a>
            <a href="#" class="module_one_desc_btn_next">Далее</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleOne"
}
</script>

<style scoped>

</style>